import AuthStore from "../../auth";
import CommonStore from "../../common/common";
import CommonUtils from "../../../utils/commonUtils";
import RootStore from "../../root";
import {BulkCnameCutoff} from "../../../types/features";
import {apiStatus} from "../../../utils/constants";
import {getS3PresignedUrl, submitBatchTask, uploadFileToS3} from "../../../clients/damBackendApi";
import {makeAutoObservable, runInAction} from "mobx";
import {getEnvironment} from "../../../utils/environment";


export default class BulkCnameCutoffStore {

    // Stores
    authStore: AuthStore;
    commonStore: CommonStore;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore
        this.commonStore = this.rootStore.commonStore

        makeAutoObservable(this)
    }

    public initializeStore() {
        runInAction(() => {
            this.commonStore.currentFeature = BulkCnameCutoff
            this.commonStore.processingApiCall(false)
            this.commonStore.clearPageMsg()
        })
    };

    public processFileUpload = async (file: any) => {

        this.commonStore.processingApiCall(true)

        CommonUtils.logNewRelicPageAction("Bulk Cname Cutoff", {
            userAction: 'Upload File',
            user: this.authStore.user
        });

        let presignedS3Url = ""
        let uploadFileName = ""
        await getS3PresignedUrl(this.authStore.token, "bulk-cname-cutoff").then((resp: any) => {
            switch(resp?.status) {
                case apiStatus.success :
                    presignedS3Url = resp?.data.body.fileUploadURL
                    uploadFileName = resp?.data.body.uploadFileName
                    break;
                default:
                    CommonUtils.displayConsoleLogError(`*** Error getting S3 Presigned URL from AWS.  Response: ` + JSON.stringify(resp));
                    break;
            }
        });

        let fileToUpload = file?.length > 0 ? file[0] : undefined

        if(!presignedS3Url || !uploadFileName) {
            CommonUtils.displayConsoleLogError(`*** Error retrieving S3 Presigned URL.  presignedS3Url: ${presignedS3Url}  uploadFileName: ${uploadFileName} `);
            this.commonStore.displayPageNotification(`Error retrieving S3 Presigned URL and Upload File Name.`, "red");
        } else {
            if(!fileToUpload) {
                CommonUtils.displayConsoleLogError(`*** Error in fetching file for upload: ` + JSON.stringify(file));
                this.commonStore.displayPageNotification(`Error in fetching file for upload.`, "red");
            }
        }

        if(presignedS3Url && fileToUpload && uploadFileName) {
            await uploadFileToS3(
                presignedS3Url,
                fileToUpload
            ).then(async (response) => {
                switch (response?.status) {
                    case 'SUCCESS' :
                        await this.submitBulkCnameCutOffJob(uploadFileName);
                        break;
                    default :
                        CommonUtils.displayConsoleLogError(`*** Error uploading file to S3 Response: ` + response.data);
                        this.commonStore.displayPageNotification(`Severe error trying to upload file to S3.`, "red")
                        break;
                }
            })
        }

        // Reset the stencil file uploader so we can re-upload without refreshing the page
        const fileUploaderInput = document.getElementById("file-uploader_input_bulk-cname-cutoff-uploader") as HTMLInputElement
        if(fileUploaderInput) {
            fileUploaderInput.value = ""
        }

        this.commonStore.processingApiCall(false)
    };

    private submitBulkCnameCutOffJob = async (uploadedFileName: string) => {
        let dataToSend = {
            fargateTaskName: "dxd-platforms-batch-bulk-cname-cutoff-task",
            s3Bucket: getEnvironment() === 'PROD' ?  "dxd-platforms-batch-production" : "dxd-platforms-batch-staging",
            s3File: uploadedFileName
        }

        const data: any = JSON.stringify(dataToSend);

        await submitBatchTask(this.authStore.token, data).then((resp: any) => {
            switch(resp?.status) {
                case apiStatus.success :
                    this.commonStore.displayPageNotification(`The CSV file you uploaded was received and is now processing.`, "green")
                    break;
                default:
                    this.commonStore.displayPageNotification(`Error in Submitting Bulk Cname CutOff Job.`, "red")
                    break;
            }
        })
    }
};