import AuthStore from "../../auth";
import CommonStore from "../../common/common";
import RootStore from "../../root";
import {makeAutoObservable, runInAction} from "mobx";
import {RunReports} from "../../../types/features";
import Validator from "../../helpers/validator";
import CommonUtils from "../../../utils/commonUtils";
import {apiStatus} from "../../../utils/constants";
import {submitBatchTask} from "../../../clients/damBackendApi";
import * as React from "react";

export enum ReportsToRunEnum {
    AM_METRICS = 'AM Metrics Report',
    UAS_USER_LEGACY_REPORT = 'Ownergroup User List Report - Legacy',
    UAS_USER_NEW_REPORT = 'Users per Location Report - New',
    SMS_LEADS_OPT_IN_REPORT = 'SMS Leads Opt-In Report'
}

export default class RunReportsStore {

    // Fields
    reportToRun: string = "";
    emailToSendReport: string = "";
    compCode: string = "";
    emailListToSendReport: string = "";

    // Stores
    authStore: AuthStore;
    commonStore: CommonStore;
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.authStore = this.rootStore.authStore
        this.commonStore = this.rootStore.commonStore

        makeAutoObservable(this)
    };

    public initializeStore() {
        runInAction(() => {
            this.commonStore.currentFeature = RunReports
            this.commonStore.processingApiCall(false)
            this.commonStore.clearPageMsg()
            this.clearReportFields()
        })
    };

    public clearReportFields() {
        this.emailToSendReport = "";
        this.reportToRun = "";
        this.compCode = "";
        this.emailListToSendReport = "";
    }

    public disableSubmitButton = () : boolean => {
        let disableButton = true;
        switch (this.reportToRun) {
            case ReportsToRunEnum.AM_METRICS:
                disableButton = !Validator.isValidEmail(this.emailToSendReport);
                break;
            case ReportsToRunEnum.UAS_USER_NEW_REPORT:
                if(this.isValidCompCode() && this.isValidListEmail() && this.compCode.length > 0)
                {
                    disableButton = false;
                }
                break;
            case ReportsToRunEnum.UAS_USER_LEGACY_REPORT:
                if(this.isValidCompCode() && this.compCode.length > 0)
                {
                    disableButton = false;
                }
                break;
            case ReportsToRunEnum.SMS_LEADS_OPT_IN_REPORT:
                if(this.isValidListEmail())
                {
                    disableButton = false;
                }
                break;
        }
        return disableButton;
    }

    public isValidEmail = () : boolean => {
        return Validator.isValidEmail(this.emailToSendReport)
    }

    public isValidListEmail = () : boolean => {
        let emailList = this.emailListToSendReport.split(",");
        if(emailList.length > 1){
            for(let i = 0; i < emailList.length; i++){
                if(!Validator.isValidEmail(emailList[i].trim())){
                    return false;
                }
            }
            return true;
        }
        return Validator.isValidEmail(this.emailListToSendReport);
    }

    public isValidCompCode = () : boolean => {
        return (!Validator.containsSpecialCharacters(this.compCode));
    }

    public onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.emailToSendReport = event.target.value
    }

    public onEmailListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.emailListToSendReport = event.target.value
    }

    public onCompCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.compCode = event.target.value
    }

    public onRunReportsSelection = (reportSelected: any) => {
        this.commonStore.clearPageMsg()
        this.commonStore.clearFeatureMsg()
        this.commonStore.clearFormErrors()
        this.reportToRun = reportSelected.target.value
        if(this.reportToRun === ReportsToRunEnum.AM_METRICS) {
            this.emailToSendReport = this.authStore.user?.email!
        }
        else if(this.reportToRun === ReportsToRunEnum.UAS_USER_NEW_REPORT || ReportsToRunEnum.SMS_LEADS_OPT_IN_REPORT) {
            this.emailListToSendReport = this.authStore.user?.email!
        }
    }

    public get reportsToRunList() {
        return Object.values(ReportsToRunEnum)
    }

    public submitReport = async () => {

        CommonUtils.logNewRelicPageAction("Run Report", {userAction: this.reportToRun, email: this.emailToSendReport})
        this.commonStore.clearFeatureMsg()
        this.commonStore.clearPageMsg()
        this.commonStore.processingApiCall(true)

        let dataToSend: any;
        switch (this.reportToRun) {
            case ReportsToRunEnum.AM_METRICS:
                dataToSend = {
                        emailAddress: this.emailToSendReport,
                        fargateTaskName: "dxd-platforms-batch-am-metrics-task"
                    };
                break;
            case ReportsToRunEnum.SMS_LEADS_OPT_IN_REPORT:
                dataToSend = {
                    emailList: this.emailListToSendReport,
                    fargateTaskName: "dxd-platforms-batch-sms-leads-opt-in-report-task"
                };
                break;
            case ReportsToRunEnum.UAS_USER_NEW_REPORT:
                dataToSend = {
                    compCode: this.compCode,
                    emailList: this.emailListToSendReport,
                    fargateTaskName: "dxd-platforms-batch-users-per-ownergroup-report-task"
                };
                break;
            case ReportsToRunEnum.UAS_USER_LEGACY_REPORT:
                dataToSend = {
                    compCode: this.compCode,
                    fargateTaskName: "dxd-platforms-batch-ownergroup-user-list-report-task"
                };
                break;
        }
        let data: any = JSON.stringify(dataToSend);

        await submitBatchTask(this.authStore.token, data).then((resp: any) => {
            switch(resp?.status) {
                case apiStatus.success :
                    this.displaySuccessMessage()
                    this.clearReportFields()
                    break;
                default:
                    this.commonStore.displayPageNotification(`Error in Submitting ${this.reportToRun} to ${this.reportToRun === ReportsToRunEnum.AM_METRICS ? this.emailToSendReport : this.emailListToSendReport}.`, "red")
                    break;
            }
        })

        this.commonStore.processingApiCall(false)
    };

    public displaySuccessMessage() {
        switch (this.reportToRun) {
            case ReportsToRunEnum.AM_METRICS: {
                this.commonStore.displayPageNotification(`Successfully Submitted ${this.reportToRun} to ${this.emailToSendReport}.`, "green")
                break;
            }
            case ReportsToRunEnum.UAS_USER_NEW_REPORT: {
                this.commonStore.displayPageNotification(`Successfully Submitted ${this.reportToRun} to ${this.emailListToSendReport}.`, "green")
                break;
            }
            case ReportsToRunEnum.UAS_USER_LEGACY_REPORT: {
                this.commonStore.displayPageNotification(`Successfully Submitted ${this.reportToRun}.`, "green")
                break;
            }
            case ReportsToRunEnum.SMS_LEADS_OPT_IN_REPORT: {
                this.commonStore.displayPageNotification(`Successfully Submitted ${this.reportToRun}. Results will be emailed to ${this.emailListToSendReport}.`, "green")
                break;
            }
        }
    }
}