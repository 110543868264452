import * as React from "react";
import {withOktaAuth} from "@okta/okta-react";
import Breadcrumbs from "@carfax-stencils/breadcrumbs";
import FileUploaderInput from '@carfax-stencils/input-file-uploader'
import Header from "../../../shared-components/layout/header/Header";
import Page from "../../../shared-components/layout/page/Page";
import Store from "../../../stores";
import {observer} from "mobx-react";
import {ProcessingApiSpinner} from "../../../shared-components/processingApiSpinner/ProcessingApiSpinner";
import "./BulkCnameCutoff.scss"
import "../../../styles/main.scss";

import {
    Notification,
    NotificationCloseButton,
    NotificationIcon,
    NotificationMessage
} from "@carfax-stencils/notification";


// Stores
const rootStore = Store.root.root;
const commonStore = rootStore.commonStore;
const bulkCnameCutoffStore = rootStore.bulkCnameCutoffStore;


export default withOktaAuth (

    class BulkCnameCutOff extends React.Component {

        state = {
            ready: false
        };

        async componentDidMount() {
            this.setState(
                {ready:true}
            )
            await this.initializePage()
        };

        initializePage = async () => {
            bulkCnameCutoffStore.initializeStore()
        };

        render() {
            return (
                <Page id="bulk-cname-cutoff-page" title="Dealer Account Management | Bulk Cname Cutoff">
                    <div id="bulk-cname-cutoff-page-div">
                        <Header id="bulk-cname-cutoff-page-header" />
                        <this.PageContent />
                    </div>
                </Page>
            );
        };

        PageContent = observer(() => {
            return (
                <div id="bulk-cname-cutoff-outer-div">
                    {commonStore.isProcessingApi && (
                        <ProcessingApiSpinner/>
                    )}
                    <div id="bulk-cname-cutoff-breadcrumbs-div" className="breadcrumb-trail">
                        <Breadcrumbs
                            id="bulk-cname-cutoff-breadcrumbs"
                            crumbs={[
                                { value: "Home", href: "#" },
                                { value: "Utilities", href: "/utilities" },
                                { value: "Bulk Cname Cutoff" },
                            ]}
                        />
                    </div>
                    <div id="bulk-cname-cutoff-page-heading-div" className="page__heading">
                        Bulk Cname Cutoff
                    </div>

                    {/*  COMMON PAGE-LEVEL NOTIFICATIONS  */}
                    {commonStore.pageMsg && (
                        <div id="bulk-cname-cutoff-notification-div" className="page-notification-div">
                            <Notification
                                id="bulk-cname-cutoff-notification"
                                role={"alert"}
                                scrollIntoView={true}
                                theme={commonStore.pageMsgTheme}>
                                <NotificationIcon/>
                                <NotificationMessage>
                                    {commonStore.pageMsg}
                                </NotificationMessage>
                                <NotificationCloseButton
                                    onClick={() => {commonStore.clearPageMsg()}}
                                />
                            </Notification>
                        </div>
                    )}

                    <div id="bulk-cname-cutoff-outer-div" className="bulk-cname-cutoff__layout-wrapper">
                        <div id="bulk-cname-cutoff-content-div" className="bulk-cname-cutoff__content-div">
                            <p id="bulk-cname-cutoff-description-text-1" className="bulk-cname-cutoff__description-text">The Bulk Cname Cutoff functionality allows you to disable Cname access to CFO and the Dealer
                            Mobile app for multiple dealerships via a single .csv file.
                            </p>
                            <p id="bulk-cname-cutoff-description-text-2" className="bulk-cname-cutoff__description-text top-padding">** NOTE - Bulk Cname Cutoff will not disable a dealer's ability to
                                use their Cname when accessing third party tools.
                            </p>
                            <p id="bulk-cname-cutoff-upload-text" className="bulk-cname-cutoff__description-text top-padding">Your .csv file must include one column labeled "Cname Cutoff Comp Code" and be no larger than 10MB.
                            </p>
                            <div id="bulk-cname-cutoff-file-uploader-div" className="bulk-cname-cutoff__file-uploader-div">
                                <FileUploaderInput
                                    name="bulk-cname-cutoff-uploader"
                                    theme="blue"
                                    validFileTypes={".csv"}
                                    onFileChange={(file) => bulkCnameCutoffStore.processFileUpload(file)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }
);