import AcctMgmtStore from "./acctMgmt/acctMgmt";
import AuditHistoryStore from "./profile/auditHistory";
import AuthStore from "./auth";
import BulkCnameCutoffStore from "./utilities/bulkCnameCutoff/bulkCnameCutoff";
import BusinessHoursStore from "./profile/businessHours";
import BusinessUserStore from "./businessUser/businessUser";
import CallMgmtSettingsStore from "./profile/callMgmtSettings";
import CommonStore from "./common/common";
import DealerDetailsStore from "./profile/dealerDetails";
import DealerProfileInfoStore from "./profile/dealerProfileInfo";
import EmailsStore from "./profile/emails";
import EmailDeletionToolStore from "./utilities/emailDeletion/emailDeletion";
import PartnersStore from "./partners/partners";
import PreferencesSelectionStore from "./preferences/preferencesSelection";
import ProfileMgmtStore from "./profile/profileMgmt";
import SearchStore from "./search/search";
import RatingsAndReviewsStore from "./profile/ratingsAndReviews";
import ReputationPartnerStore from "./reputationPartner/reputationPartner";
import ReputationPartnerHistoryStore from "./reputationPartner/reputationPartnerHistory";
import RunReportsStore from "./utilities/runReports/runReports";
import PreferencesSearchStore from "./search/preferences/preferencesSearch";
import SmcSettingsStore from "./profile/smcSettings";
import UclSettingsStore from "./profile/uclSettings";
import UserAuditHistoryStore from "./acctMgmt/userAuditHistory";
import {makeAutoObservable} from "mobx";
import BulkInviteStore from "./utilities/bulkInvite/bulkInvite";
import RegenerateTopRatedDealersStore from "./utilities/regenerateTopRatedDealers/regenerateTopRatedDealers";
import SecondaryNamesStore from "./profile/secondaryNames";
import MoveProfilePreferences from "./profile/moveProfilePreferences";

export default class RootStore {

    // Child Stores
    acctMgmtStore: AcctMgmtStore;
    authStore: AuthStore;
    auditHistoryStore: AuditHistoryStore;
    bulkCnameCutoffStore: BulkCnameCutoffStore;
    bulkInviteStore: BulkInviteStore;
    regenerateTopRatedDealersStore: RegenerateTopRatedDealersStore;
    businessHoursStore: BusinessHoursStore;
    businessUserStore: BusinessUserStore;
    callMgmtSettingsStore: CallMgmtSettingsStore;
    commonStore: CommonStore;
    dealerDetailsStore: DealerDetailsStore;
    dealerProfileInfoStore: DealerProfileInfoStore;
    emailDeletionToolStore: EmailDeletionToolStore
    emailsStore: EmailsStore;
    moveProfilePreferences: MoveProfilePreferences;
    partnersStore: PartnersStore;
    preferencesSelectionStore: PreferencesSelectionStore;
    profileMgmtStore: ProfileMgmtStore;
    searchStore: SearchStore;
    ratingsAndReviewsStore: RatingsAndReviewsStore;
    reputationPartnerStore: ReputationPartnerStore;
    reputationPartnerHistoryStore: ReputationPartnerHistoryStore;
    runReportsStore: RunReportsStore;
    searchResultsStore: PreferencesSearchStore;
    secondaryNamesStore: SecondaryNamesStore;
    smcSettingsStore: SmcSettingsStore;
    uclSettingsStore: UclSettingsStore;
    userAuditHistoryStore: UserAuditHistoryStore;

    constructor() {
        // !! Child Stores need to be in this specific order
        this.authStore = new AuthStore(this)
        this.commonStore = new CommonStore(this)
        this.businessUserStore = new BusinessUserStore(this)
        this.businessHoursStore = new BusinessHoursStore(this)
        this.emailDeletionToolStore = new EmailDeletionToolStore(this)
        this.emailsStore = new EmailsStore(this)
        this.auditHistoryStore = new AuditHistoryStore(this)
        this.dealerProfileInfoStore = new DealerProfileInfoStore(this)
        this.partnersStore = new PartnersStore(this)
        this.secondaryNamesStore = new SecondaryNamesStore(this)
        this.uclSettingsStore = new UclSettingsStore(this)
        this.ratingsAndReviewsStore = new RatingsAndReviewsStore(this)
        this.smcSettingsStore = new SmcSettingsStore(this)
        this.callMgmtSettingsStore = new CallMgmtSettingsStore(this)
        this.dealerDetailsStore = new DealerDetailsStore(this)
        this.searchStore = new SearchStore(this)
        this.profileMgmtStore = new ProfileMgmtStore(this)
        this.reputationPartnerStore = new ReputationPartnerStore(this)
        this.reputationPartnerHistoryStore = new ReputationPartnerHistoryStore(this)
        this.searchResultsStore = new PreferencesSearchStore()
        this.preferencesSelectionStore = new PreferencesSelectionStore(this)
        this.acctMgmtStore = new AcctMgmtStore(this)
        this.userAuditHistoryStore = new UserAuditHistoryStore(this)
        this.runReportsStore = new RunReportsStore(this)
        this.bulkCnameCutoffStore = new BulkCnameCutoffStore(this)
        this.bulkInviteStore = new BulkInviteStore(this)
        this.regenerateTopRatedDealersStore = new RegenerateTopRatedDealersStore(this)
        this.moveProfilePreferences = new MoveProfilePreferences(this)

        makeAutoObservable(this);
    };
}
